var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[(_vm.$acl.check('isAdmin'))?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[(_vm.logo)?_c('v-img',{attrs:{"src":_vm.logo,"width":"100px","max-height":"45px","contain":"","to":{ name: 'Home' }}}):_vm._e()],1)],1),_c('v-spacer'),_c('v-flex',{attrs:{"shrink":""}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("person")]),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v(" "+_vm._s(_vm.user.firstname)+" ")]),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'Home' }}},[_c('v-list-item-title',[_vm._v("Accueil")])],1),_c('v-list-item',{attrs:{"to":{ name: 'Profile' }}},[_c('v-list-item-title',[_vm._v("Mon profil")])],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.logout($event)}}},[_c('v-list-item-title',[_vm._v("Se déconnecter")])],1)],1)],1)],1)],1),(_vm.$acl.check('isAdmin'))?_c('v-navigation-drawer',{attrs:{"temporary":"","absolute":"","id":"drawer"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"mx-auto"},[_c('v-avatar',[_c('img',{attrs:{"alt":"","src":require("@/assets/logo.png")}})])],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$resetHistory();
          _vm.$router.push({ name: 'Users' });}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("vpn_key")])],1),_c('v-list-item-title',[_vm._v("Utilisateurs")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.$resetHistory();
          _vm.$router.push({ name: 'Clients' });}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("group")])],1),_c('v-list-item-title',[_vm._v("Clients")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.$resetHistory();
          _vm.$router.push({ name: 'Sites' });}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("location_on")])],1),_c('v-list-item-title',[_vm._v("Sites")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.$resetHistory();
          _vm.$router.push({ name: 'SensorTypes' });}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("vpn_key")])],1),_c('v-list-item-title',[_vm._v("Types de capteur")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.$resetHistory();
          _vm.$router.push({ name: 'Integrations' });}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("vpn_key")])],1),_c('v-list-item-title',[_vm._v("Intégrations")])],1),_c('v-divider')],1)],1):_vm._e(),(_vm.$acl.check('isAdmin'))?_c('v-footer',{staticClass:"caption text-center text-md-left",attrs:{"app":"","dark":"","color":"primary","padless":"","absolute":""}},[_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" POUR TOUT CONSEIL"),_c('br'),_vm._v(" Tél. : +33 5 35 54 71 60"),_c('br'),_vm._v(" contact@wineryplatform.com ")]),_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" WPlatform"),_c('br'),_vm._v(" 39 rue Marceau"),_c('br'),_vm._v(" 33000 Bordeaux (France) ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" © Copyright 2020 - "),_c('v-btn',{staticClass:"text-capitalize",attrs:{"href":"https://wineryplatform.com","text":"","small":"","target":"_blank"}},[_vm._v("WPlatform")])],1)],1):_c('v-footer',{staticClass:"caption text-center text-md-left",attrs:{"app":"","dark":"","color":"primary","padless":"","absolute":""}},[_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" Tél. : "+_vm._s(this.phone)),_c('br'),_vm._v(" "+_vm._s(this.email)+" ")]),_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" "+_vm._s(this.clientName)),_c('br'),_vm._v(" "+_vm._s(this.address)),_c('br'),_vm._v(" "+_vm._s(this.postCode)+" "+_vm._s(this.city)+" (France) ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":3}},[_vm._v(" © Copyright 2022 - WPlatform ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }