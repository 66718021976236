<template>
  <div class="navigation">
    <v-toolbar color="primary" dark>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$acl.check('isAdmin')"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link :to="{ name: 'Home' }">
          <!-- <v-img
            v-if="$acl.check('isAdmin')"
            :src=""
            width="100px"
            max-height="45px"
            contain
            :to="{ name: 'Home' }"
          ></v-img> -->
          <v-img
              v-if="logo"
              :src="logo"
              width="100px"
              max-height="45px"
              contain
              :to="{ name: 'Home' }"
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-flex shrink>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon class="mr-2">person</v-icon>
              <span class="hidden-md-and-down">
                {{ user.firstname }}
              </span>
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'Home' }">
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Profile' }">
              <v-list-item-title>Mon profil</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="logout">
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
      id="drawer"
      v-if="$acl.check('isAdmin')"
    >
      <v-toolbar color="primary" dark>
        <v-toolbar-title class="mx-auto">
          <v-avatar><img alt="" src="@/assets/logo.png"/></v-avatar>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          @click="
            $resetHistory();
            $router.push({ name: 'Users' });
          "
        >
          <v-list-item-action>
            <v-icon>vpn_key</v-icon>
          </v-list-item-action>
          <v-list-item-title>Utilisateurs</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="
            $resetHistory();
            $router.push({ name: 'Clients' });
          "
        >
          <v-list-item-action>
            <v-icon>group</v-icon>
          </v-list-item-action>
          <v-list-item-title>Clients</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="
            $resetHistory();
            $router.push({ name: 'Sites' });
          "
        >
          <v-list-item-action>
            <v-icon>location_on</v-icon>
          </v-list-item-action>
          <v-list-item-title>Sites</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="
            $resetHistory();
            $router.push({ name: 'SensorTypes' });
          "
        >
          <v-list-item-action>
            <v-icon>vpn_key</v-icon>
          </v-list-item-action>
          <v-list-item-title>Types de capteur</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            @click="
            $resetHistory();
            $router.push({ name: 'Integrations' });
          "
        >
          <v-list-item-action>
            <v-icon>vpn_key</v-icon>
          </v-list-item-action>
          <v-list-item-title>Intégrations</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>

    <v-footer
      v-if="$acl.check('isAdmin')"
      app
      dark
      color="primary"
      padless
      class="caption text-center text-md-left"
      absolute
    >
      <v-col cols="12" :md="3">
        POUR TOUT CONSEIL<br />
        Tél. : +33 5 35 54 71 60<br />
        contact@wineryplatform.com
      </v-col>
      <v-col cols="12" :md="3">
        WPlatform<br />
        39 rue Marceau<br />
        33000 Bordeaux (France)
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" :md="3">
        © Copyright 2020 -
        <v-btn
          href="https://wineryplatform.com"
          text
          small
          class="text-capitalize"
          target="_blank"
          >WPlatform</v-btn
        >
      </v-col>
    </v-footer>
    <v-footer
        v-else
        app
        dark
        color="primary"
        padless
        class="caption text-center text-md-left"
        absolute
    >
      <v-col cols="12" :md="3">
        Tél. : {{ this.phone }}<br />
        {{ this.email }}
      </v-col>
      <v-col cols="12" :md="3">
        {{ this.clientName }}<br />
        {{ this.address }}<br />
        {{ this.postCode }} {{ this.city }} (France)
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" :md="3">
        © Copyright 2022 -
        WPlatform
      </v-col>
    </v-footer>
  </div>
</template>
<script>

import {getSitePromise, getUserSite} from "@/vuex/modules/site";

export default {
  data() {
    let user = this.$store.state.profile.user;

    return {
      drawer: false,
      ready: false,
      site: null,
      logo: null,
      phone: user.clients[0]["phone"],
      email: user.clients[0]["email"],
      clientName: user.clients[0]["name"],
      address: user.clients[0]["address"],
      postCode: user.clients[0]["postCode"],
      city: user.clients[0]["city"],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  mounted() {

    let user = this.$store.state.profile.user;
    this.site = getUserSite(user, this.$route.params.site_id);

    this.logo = (user.role === "admin" ? require('../assets/logo3.png') : (this.$route.params.site_id != null && this.site.client["logo_url"] != null ? this.site.client["logo_url"] : ( user.clients[0]["logo_url"] != null ? user.clients[0]["logo_url"] : 'https://cdn4.iconfinder.com/data/icons/software-line/32/software-line-02-512.png')))

    this.$vuetify.theme.currentTheme.primary = (user.role === "admin" ? "#972b34" : (this.$route.params.site_id != null ? this.site.client["color"] : this.$store.state.profile.user.clients[0]["color"]));

    this.phone = (this.$route.params.site_id != null ? this.site.client["phone"] : this.phone);
    this.clientName = (this.$route.params.site_id != null ? this.site.client["name"] : this.clientName);
    this.email = (this.$route.params.site_id != null ? this.site.client["email"] : this.email);
    this.address = (this.$route.params.site_id != null ? this.site.client["address"] : this.address);
    this.postCode = (this.$route.params.site_id != null ? this.site.client["postCode"] : this.postCode);
    this.city = (this.$route.params.site_id != null ? this.site.client["city"] : this.city);
    this.phoneNumber = (this.$route.params.site_id != null ? this.site.client["phone"] : this.phoneNumber);

    this.ready = true;
  },
  methods: {
    logout() {
      this.$store.dispatch("authLogout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
