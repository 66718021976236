import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import './plugins/moment'
import acl from './plugins/acl'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import './plugins/chart'

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

import breadcrumb from './plugins/breadcrumb'
Vue.use(breadcrumb)
import helper from './plugins/helper'
Vue.use(helper)

import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation)
import Snackbar from './components/Snackbar.vue'
Vue.component('Snackbar', Snackbar)

Vue.config.productionTip = false

// console.log("api url: ",process.env.VUE_APP_API_URL)

new Vue({
    vuetify,
    router,
    acl,
    store,
    render: h => h(App)
}).$mount('#app')
